import { ref } from "@vue/reactivity";
// import { watch } from "vue";
import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
// import moment from "moment";
import engine from '../core/DataApi'
import { useStore } from 'vuex'

export default function contartHooks() {

    const store = useStore()
    const clientListe = ref([])
    const agencyListe = ref([])
    const familyListe = ref([])
    const contrat_informations = ref([])
    const subFamilyListe = ref([])
    const parcListe = ref([])
    const contratListe = ref([])
    const retraitListe = ref([])
    const options = ref([])
    const search = ref(undefined)
    const homeHeader = ref([{
            value: "N° Contrat Comp",
            text: 'Num Contrat',
            sortable: true
        }, {
            value: 'Societe',
            text: 'Client',
            sortable: true
        },
        {
            value: 'LocdataS_::ID_Grille_nom',
            text: 'Type de location',
            sortable: true
        },
        {
            // value: 'LocdataS_::Date Debut Loc',
            value: 'sortie',
            text: 'Sortie',
            sortable: true
        }, {
            // value: 'LocdataS_::Date retour',
            value: 'retour',
            text: 'Retour',
            sortable: true
        }, {
            value: 'LocdataS_::N° Mat interne',
            text: 'Matricule',
            sortable: true
        },
        // {
        //     value: 'facturation',
        //     text: 'Envoyer pour facturation',
        //     sortable: true
        // },
        {
            value: 'facturation',
            text: 'Facturé',
            sortable: true
        }
    ])

    const getContratsListe = function(callback) {
        // if (callback)
        contratListe.value = []
        engine.get_contrats(function(data) {
            console.log("get_contrats", data);
            if (data.code == 0) {
                contratListe.value = data.data.data.map((val) => val.fieldData)
                contratListe.value = contratListe.value.sort((a, b) => b['ID'] - a['ID'])
                for (let i = 0; i < contratListe.value.length; i++) {
                    contratListe.value[i]['facturation'] = contratListe.value[i]['Date facturation']
                    contratListe.value[i]['key'] = contratListe.value[i]['LocdataS_::N° Mat interne'] +
                        contratListe.value[i]['LocdataS_::ID_Grille_nom'] +
                        contratListe.value[i]['Societe'] +
                        contratListe.value[i]['N° Contrat Comp']
                }
            }
            if (callback) {
                console.log("run callback");
                callback()
            }
        })
    }

    const get_contratListe_filtered = computed(function() {
        if (contratListe.value == undefined)
            return contratListe.value
        if (search.value && search.value.length > 0) {
            return contratListe.value.filter((val) => val.key.toLowerCase().includes(search.value.toLowerCase()))
        } else {
            return contratListe.value;
        }
    })

    const getClientListe = function() {
        clientListe.value = []
        engine.get_clients(function(data) {
            if (data.code == 0) {
                clientListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getFamilyList = function() {
        engine.get_family_list(function(data) {
            if (data.code == 0) {
                familyListe.value = Object.values(data.data.data).map((val) => val[0])
            }
        })
    }

    const getAgencyList = function() {
        console.log("getAgencyList");
        engine.get_agency_list(function(data) {
            console.log(0);

            if (data.code == 0) {
                console.log("data", data);
                agencyListe.value = data.data.data.map((val) => val.fieldData)
            }
            console.log("agencyListe", agencyListe.value);

        })
    }

    const getSubFamilyList = function() {
        engine.get_sub_family_list(function(data) {
            if (data.code == 0) {
                subFamilyListe.value = data.data.data.data
            }
        })
    }

    const getRetraitList = function() {
        engine.get_retrait_list(function(data) {
            if (data.code == 0) {
                retraitListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getContratInformations = function() {
        engine.get_contrat_informations(function(data) {
            if (data.code == 0) {
                contrat_informations.value = data.data.data
            }
        })
    }

    const getParcListe = function() {
        engine.get_parc(function(data) {
            if (data.code == 0) {
                parcListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getOptions = function() {
        engine.get_options(function(data) {
            if (data.code == 0) {
                options.value = data.data.data.map((val) => ({ name: val.fieldData.Name, value: JSON.parse(val.fieldData.Value) }))
            }
        })
    }


    const get_user_information = computed(function() {
        return store.state.user;
    })

    onMounted(() => {
        getContratsListe()
        getClientListe()
        getParcListe()
        getFamilyList()
        getSubFamilyList()
        getContratInformations()
        getRetraitList()
        getOptions()
        getAgencyList()
            // getMaterialListe()
    })

    return { getClientListe, getContratsListe, agencyListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information, search, get_contratListe_filtered, options };
}