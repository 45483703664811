<template>
  <div align="center" class="">
    <v-col class="pa-1" variant="text"
      style="border-radius: 0px; background-color: white;height: 69vh; overflow: auto;">
      <v-col>
        <v-row>
          <v-col class="pa-1 pt-2">
            <div style="text-transform: uppercase; font-weight: bold" class="pb-2">
              Départ
            </div>
            <v-select label="Sélectionner un point de départ" variant="outlined" :disabled="isPathLoaded"
              :items="position" hide-details v-model="localAgencyFilter"
              :item-title="localAgencyFilter && localAgencyFilter.client == undefined ? 'clients[1]' : 'client'"
              density="compact" no-data-text="Aucun trajets disponible" return-object>
              <template v-slot:item="{ props, item }">
                <div v-bind="props">
                  <v-list-item v-bind="props" :title="item.value.client"></v-list-item>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col class="pa-1 pt-2">
            <div style="text-transform: uppercase; font-weight: bold" class="pb-2">
              Arrivée
            </div>
            <div style="
          position: relative;
        " align="center">
              <div>
                <v-select :disabled="!localAgencyFilter" variant="outlined" label="Sélectionner une destination"
                  v-model="destinationItem" hide-details density="compact" :items="getAgencyFiltered"
                  item-title="clients[1]" return-object>
                </v-select>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!-- {{ localAgencyFilter }} -->
      <v-col class="">
        <v-row class="">
          <v-col cols="6" class="pa-1">
            <v-card class="pa-2" variant="flat" style="background-color: #A5D6A7;">
              <h3 class="py-2">
                A Récupérer
              </h3>
              <div style="overflow: auto; height: 39vh; background-color:#E8F5E9;border-radius:5px">
                <v-item-group v-if="localAgencyFilter && destinationItem" class="pa-1" multiple v-model="selectedContratRec">
                  <v-col v-for="(contrat, idx) in getDispoStockMaterielListe(getDestinationClient)" :key="idx" cols="12"
                    class="pa-1">
                    <v-item v-slot="{ isSelected, toggle }">
                      <v-card class="pa-2" :color="isSelected ? 'primary' : ''" @click="function () {
                        toggle()
                        // addElemToMaps(destinationItem)
                      }" align="left">
                        <v-scroll-y-transition>
                          <div>
                            <div style="font-weight: bold;">
                              {{ contrat.CLD_DESCRIPTION }}
                            </div>
                            {{ contrat.title }}<br>
                            <span style="color:red;font-weight: bold;">
                              {{ contrat.CLD_DATE_DEPART }}
                            </span> - {{ contrat.CLD_POIDS }} Kg
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-item-group>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="pa-1">
            <v-card class="pa-2" variant="flat" style="background-color: #9FA8DA">
              <h3 class="py-2">
                A Livrer
              </h3>
              <div style="overflow: auto; height: 39vh;background-color:#E3F2FD;border-radius:5px">
                <v-item-group v-if="localAgencyFilter && destinationItem" multiple v-model="selectedContratLiv">
                  <v-col v-for="(contrat, idx) in getDispoSortieMaterielListe(getDestinationClient)" :key="idx"
                    cols="12" class="pa-1">
                    <v-item v-slot="{ isSelected, toggle }">
                      <v-card class="pa-2" :color="isSelected ? 'primary' : ''" @click="toggle" align="left">
                        <v-scroll-y-transition>
                          <div>
                            <div style="font-weight: bold;">
                              {{ contrat.CLD_DESCRIPTION }}
                            </div>
                            {{ contrat.title }}<br>
                            <span style="color:red;font-weight: bold;">
                              {{ contrat.CLD_DATE_RETOUR }}
                            </span> - {{ contrat.CLD_POIDS }} Kg
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-item-group>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <div>
        <v-divider class="mt-2 mb-3"></v-divider>
        <div>
          <v-col cols="auto" class="pa-0">
            <!-- <div expand-icon="mdi-menu-down" style="position: relative;font-size: 12px; border-radius: 5px;width: 100%;"
              class="pa-2">
              {{ destinationItem.clients[0] }}
              <v-icon class=""> mdi-arrow-right-bold </v-icon>
              {{ destinationItem.clients[1] }}
            </div> -->
            <v-card v-if="localAgencyFilter && destinationItem" width="100%" @click="addElemToMaps(destinationItem)"
              class="pa-2" style="background-color: #81C784;color:white;font-weight: bold">
              <div>
                Valider mon trajet
              </div>
              <div>
                {{ destinationItem.clients[0] }}
                <v-icon class=""> mdi-arrow-right-bold </v-icon>
                {{ destinationItem.clients[1] }}
              </div>
            </v-card>
            <v-card v-else class="pa-2" style="background-color: red;color:white;margin-top:30px;" height="100%">
              <div v-if="localAgencyFilter == undefined" color="red" variant="text" style="font-weight: bold;">
                Aucun point de départ séléctionné
              </div>
              <div v-else-if="destinationItem == undefined" color="red" variant="text" style="font-weight: bold;">
                Aucun point d'arrivée séléctionné
              </div>
            </v-card>
          </v-col>
        </div>
      </div>
      <!-- <v-col style="" align="center"> -->
      <!-- <v-row class="pt-2">
          <v-col cols="6" class="pa-3 pr-1">
            <v-btn width="100%" @click="showFullPath()" variant="text" color="#3F51B5"
              style="border: solid 1px lightgrey" :disabled="isPathLoaded || itineraireOrder.data.length === 0">
              Visualiser l'itinéraire
            </v-btn>
          </v-col> -->
      <!-- <v-col cols="6" class="pa-3 pl-1">
            <v-btn width="100%" @click="validItineraire()" variant="text" color="#81C784"
              style="border: solid 1px lightgrey" :disabled="isPathLoaded || itineraireOrder.data.length === 0">
              Valider l'itinéraire
            </v-btn>
          </v-col> -->
      <!-- </v-row> -->
      <!-- </v-col> -->
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedContratRec: [],
      selectedContratLiv: [],
      firstLoad: true,
      localAgencyFilter: undefined,
      destinationItem: undefined
    };
  },
  props: {
    isPathLoaded: {},
    showFullPath: {},
    getAgencyFiltered: {},
    position: {},
    itineraireOrder: {},
    setItineraireOrder: {},
    validItineraire: {},
    // agencyFilter: {},
    gpsPositionByClient: {},
    lastItineraireOrder: {}
  },
  computed: {
    getDestinationClient() {
      return this.position.find((val) => val.client == this.destinationItem.clients[1])
    }
  },
  methods: {
    getDispoSortieMaterielListe(item) {
      let dataSortie = this.gpsPositionByClient[item.id].dataSortie
      let finalData = []
      if (dataSortie && dataSortie.length != 0)
        for (let i = 0; i < dataSortie.length; i++) {
          let softData = {
            title: dataSortie[i].CLD_NUM_CONTRAT + ' - ' + dataSortie[i].CLD_MATRICULE,
            CLD_DESCRIPTION: dataSortie[i].CLD_DESCRIPTION,
            CLD_MATRICULE: dataSortie[i].CLD_MATRICULE,
            CLD_NUM_CONTRAT: dataSortie[i].CLD_NUM_CONTRAT,
            CLD_SOUS_FAMILLE: dataSortie[i].CLD_SOUS_FAMILLE,
            CLD_DATE_RETOUR: dataSortie[i].CLD_DATE_RETOUR,
            CLD_POIDS: dataSortie[i].CLD_POIDS,
            CLD_DATE_DEPART: dataSortie[i].CLD_DATE_DEPART,
          }
          finalData.push(softData)
        }
      return finalData
    },
    getDispoStockMaterielListe(item) {
      let dataStock = this.gpsPositionByClient[item.id].dataStock
      let finalData = []
      if (dataStock && dataStock.length != 0)
        for (let i = 0; i < dataStock.length; i++) {
          let softData = {
            title: dataStock[i].CLD_NUM_CONTRAT + ' - ' + dataStock[i].CLD_MATRICULE,
            CLD_DESCRIPTION: dataStock[i].CLD_DESCRIPTION,
            CLD_MATRICULE: dataStock[i].CLD_MATRICULE,
            CLD_NUM_CONTRAT: dataStock[i].CLD_NUM_CONTRAT,
            CLD_SOUS_FAMILLE: dataStock[i].CLD_SOUS_FAMILLE,
            CLD_DATE_RETOUR: dataStock[i].CLD_DATE_RETOUR,
            CLD_DATE_DEPART: dataStock[i].CLD_DATE_DEPART,
            CLD_POIDS: dataStock[i].CLD_POIDS,
          }
          finalData.push(softData)
        }
      return finalData
    },
    addElemToMaps(elem) {
      let listRec = this.getDispoStockMaterielListe(this.getDestinationClient)
      let listLiv = this.getDispoSortieMaterielListe(this.getDestinationClient)

      elem['matriculeListeSortie'] = this.selectedContratLiv.map((val) => listLiv[val])
      elem['matriculeListeStock'] = this.selectedContratRec.map((val) => listRec[val])

      this.setItineraireOrder(elem)
      this.localAgencyFilter = elem
    },
  },
  watch: {
    lastItineraireOrder: {
      handler: function (val) {
        if (val.data.length > 0) {
          let trajet = val.data[val.data.length - 1];
          if (trajet.id != this.localAgencyFilter.id) {
            this.localAgencyFilter = this.position.find((elem) => elem.id == trajet.idTo)
          }
        }
      },
      deep: true
    },
    localAgencyFilter(val) {
      if (val.client == undefined)
        this.localAgencyFilter = this.position.find((val2) => val.clients[0] == val2.client)
      else {
        this.$emit("updateFilter", val);
        this.destinationItem = undefined
        this.selectedContratLiv = []
        this.selectedContratRec = []
      }
    },
    // position(val) {
    //   if (val.length != 0) {
    //     this.localAgencyFilter = this.position[0]
    //   }
    // }
  },
};
</script>

<style></style>